var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"content-class":"dialogBgNone"},model:{value:(_vm.wrapperDialog),callback:function ($$v) {_vm.wrapperDialog=$$v},expression:"wrapperDialog"}},[_c('div',{staticClass:"myDialog"},[_c('div',{staticClass:"myDialog_inner"},[_c('button',{staticClass:"myDialog_closeBtn",attrs:{"type":"button"},on:{"click":function($event){_vm.wrapperDialog = false}}},[_c('span',{staticClass:"myDialog_closeBtn_icon"}),_vm._v("閉じる ")]),_c('div',{staticClass:"myDialog_box"},[_c('div',{staticClass:"myDialog_box_scroll"},[_c('p',{staticClass:"myDialog_text"},[_vm._v(" 解約を受け付けます。"),_c('br'),_vm._v("解約を希望されるサービスを選択してください。 ")]),_vm._l((_vm.packageList),function(p){return _c('div',{key:p.id,staticClass:"unsubList"},[(
                _vm.subscriptionInfo.isSubscriber(p.id) &&
                  _vm.subscriptionInfo.getSubscriptionInfoByPackageId(p.id)
                    .continue_flg
              )?_c('div',{staticClass:"unsubList_item"},[_c('p',{staticClass:"unsubList_name"},[_vm._v(" "+_vm._s(p.packageName)+" ")]),_c('div',{staticClass:"unsubList_areaText"},[_c('router-link',{staticClass:"unsubList_link",attrs:{"to":{
                    name: 'subscription-unsubscribe',
                    params: { id: p.id }
                  }}},[_vm._v("解約する ")])],1)]):_vm._e(),(
                _vm.subscriptionInfo.isSubscriber(p.id) &&
                  _vm.subscriptionInfo.getSubscriptionInfoByPackageId(p.id)
                    .continue_flg &&
                  _vm.subscriptionInfo.getSubscriptionInfoByPackageId(p.id)
                    .last_contract_flg &&
                  !!p.packageEndDt
              )?_c('ul',{staticClass:"unsubList_cauList u-cauList"},[_c('li',[_vm._v(" ※"+_vm._s(_vm._f("formatYmd")(p.packageEndDt))+" 06時00分サービス終了 ")])]):_vm._e()])})],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }