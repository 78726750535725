














































































































































































































































































import { Component, Vue } from 'vue-property-decorator';

import { ResSubscriptionInfo } from '@/gen';
import { SubscriptionInfo } from '@/models/Subscription';
import { SubscriptionRepository } from '@/repositories/SubscriptionRepository';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';
import { SubscriptionPackageCmsRepository } from '@/repositories/SubscriptionPackageCmsRepository';
import {
  isPackageBeforeOneMonthFromClosing,
  isNextUpdateForFree
} from '@/common/subscriptionUtils';

import SubscriptionUnsubscribeListDialog from '@/components/SubscriptionUnsubscribeListDialog.vue';

@Component({
  components: {
    SubscriptionUnsubscribeListDialog
  }
})
export default class P0222 extends Vue {
  // エラーメッセージ
  errMsg = '';
  // サブスク登録情報(from odin)
  subscriptionInfo = {} as SubscriptionInfo;
  // パッケージ情報(from microCMS)
  packageList: Array<SubscriptionPackageItem> = [];
  // 解約予約モーダル表示フラグ
  isUnsubscribeListDialogOpening = false;

  created() {
    this.initialize();
  }

  initialize() {
    this.getPackageList();
    this.getInfo();
  }

  // サブスクリポジトリ for odin
  get subscriptionRepo() {
    return new SubscriptionRepository();
  }

  // サブスクリポジトリ for microCMS
  get subscriptionCmsRepo() {
    return new SubscriptionPackageCmsRepository();
  }

  // サブスクパッケージ一覧の取得 from microCMS
  async getPackageList() {
    const listItem = await this.subscriptionCmsRepo.get();
    if (listItem) {
      this.packageList = listItem.filter(
        pkg => !this.isPackageClosed(pkg.packageEndDt)
      );
      if (this.$route.hash) {
        this.$nextTick(() => {
          this.$scrollTo(this.$route.hash);
        });
      }
    } else {
      this.errMsg = this.$msg.get('2000078');
    }
  }

  // サブスク登録情報の取得 from odin
  async getInfo() {
    await this.subscriptionRepo
      .getSubscriptionInfo()
      .then(res => {
        this.subscriptionInfo = res;
      })
      .catch((errCode: number) => {
        switch (errCode) {
          case 42206: // サブスク契約が1件も存在しない場合、エラーメッセージはセットせず SubscriptionInfo を初期化する
            this.subscriptionInfo = SubscriptionInfo.valueOf({
              card_info: {
                masked_card_number: '',
                expiry_ym: ''
              },
              subscription_contracts: []
            } as ResSubscriptionInfo);
            break;
          default:
            this.errMsg = this.$msg.get('2000071', {
              errorCode: errCode
            });
            break;
        }
      });
  }

  // getPayErrMsg エラーメッセージをセット
  getPayErrMsg(packageId: string) {
    // カード有効期限切れ等による「ユーザ責エラー」の契約が存在する場合、契約更新対象とならないため AM 6:00 に契約終了となる
    // AM 6:00 より後は契約状態が「未登録」状態になるため、エラーメッセージを出し分ける
    if (this.subscriptionInfo.isSubscriber(packageId)) {
      return this.$msg.get('2000106'); // AM 6:00 より前
    } else {
      return this.$msg.get('2000108'); // AM 6:00 より後
    }
  }

  // リクエスト日時 が パッケージ登録操作終了日時 以降である場合 true を返却する
  isPackageRegistrationClosed(registrationCloseDt: string) {
    return new Date() >= new Date(registrationCloseDt);
  }

  // リクエスト日時 が パッケージ終了日時 以降である場合 true を返却する
  isPackageClosed(packageEndDt: string | undefined) {
    if (!packageEndDt) {
      return false;
    }
    const packageEndTime = new Date(packageEndDt);
    packageEndTime.setHours(6); // AM6:00に設定
    return new Date() >= packageEndTime;
  }

  // パッケージ登録操作終了日時より1ヶ月以内の場合 true を返却する
  isPackageBeforeOneMonthFromClosing(registrationCloseDt: string) {
    return isPackageBeforeOneMonthFromClosing(registrationCloseDt);
  }

  // 「更新あり/OER負担」パッケージにて、次回更新がパッケージ終了間近である場合 true を返却する
  isNextUpdateForFree(
    packageEndDt: string,
    payInterval: number,
    packageEndingPattern: string,
    endDt: string
  ) {
    return isNextUpdateForFree(
      packageEndDt,
      payInterval,
      packageEndingPattern,
      endDt
    );
  }

  // ユーザ表示用にパッケージ登録操作終了日時から1秒引いた時刻を算出
  getOneSecondBeforeRegistrationCloseDt(registrationCloseDt: string) {
    return new Date(registrationCloseDt).getTime() - 1000;
  }
}
