






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';
import { SubscriptionInfo } from '@/models/Subscription';
import { SUBSCRIPTION_PACKAGE_NOT_UPDATE } from '@/common/constants';

@Component({
  components: {}
})
export default class SubscriptionUnsubscribeListDialog extends Vue {
  notForUpdatePackage: string = SUBSCRIPTION_PACKAGE_NOT_UPDATE;

  @Prop({ required: true })
  readonly value!: boolean;

  @Prop({ required: true })
  readonly packageList!: Array<SubscriptionPackageItem>;

  @Prop({ required: true })
  readonly subscriptionInfo!: SubscriptionInfo;

  get wrapperDialog() {
    return this.value;
  }

  set wrapperDialog(flag: boolean) {
    this.$emit('input', flag);
  }
}
